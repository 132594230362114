import {
  FormLabel,
  FormControlProps,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { MaybeHex } from 'modules/theming/components/ColorPickerMenu/types'
import {
  colorWithLightness,
  isColorDark,
  isColorReadable,
  makeColorReadable,
} from 'utils/color'

import { ColorPalettePicker } from '../ColorPalettePicker'
import { LinearGradient } from './types'

export const DEFAULT_GRADIENT_COLOR = '#888888'
const DEFAULT_ANGLE = 135 // Will show up well in vertical lines (blockquote) and horizontal (button)

type LinearGradientPickerProps = {
  value: LinearGradient | null
  updateValue: (value: LinearGradient) => void
} & FormControlProps

export const LinearGradientPicker = ({
  value,
  updateValue,
}: LinearGradientPickerProps) => {
  const updateColors = (colors: MaybeHex[]) => {
    updateValue({ ...value, colors })
  }

  const updateAngle = (angle: number) => {
    updateValue({ colors, angle })
  }

  const colors = value?.colors || [null, null]
  const displayAngle = value?.angle ?? DEFAULT_ANGLE

  return (
    <VStack spacing={2} align="flex-start">
      <ColorPalettePicker
        value={colors}
        updateValue={updateColors}
        maxColors={3}
        minColors={2}
        defaultValue={'#888888'}
      />
      <FormLabel mb="0">
        <Trans>Gradient angle</Trans>
      </FormLabel>
      <Slider
        aria-label="angle-slider"
        min={0}
        max={360}
        step={5}
        value={displayAngle}
        onChange={updateAngle}
        w="100%"
        colorScheme="trueblue"
        size="md"
        mt={'1.5rem !important'}
      >
        <SliderMark
          value={displayAngle}
          textAlign="center"
          mt={-8}
          ml={-3}
          fontSize="sm"
        >
          {displayAngle}°
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </VStack>
  )
}

export const generateLinearGradientCSS = (gradient?: LinearGradient) => {
  if (!gradient || gradient.colors.length <= 1 || gradient.disabled)
    return undefined
  const colorStops = gradient.colors
    .map(
      (c, i) =>
        `${c || DEFAULT_GRADIENT_COLOR} ${
          (10 + i * 80) / (gradient.colors.length - 1)
        }%`
    )
    .join(', ')
  return `linear-gradient(${gradient.angle || DEFAULT_ANGLE}deg, ${colorStops})`
}

export const readableGradient = (
  gradient: LinearGradient | undefined,
  contrastValue: string,
  contrastRatio?: number,
  whiteBlackOnly?: boolean
): LinearGradient | undefined => {
  if (!gradient) return undefined
  if (
    whiteBlackOnly &&
    gradient.colors.some(
      (c) =>
        !isColorReadable(
          c || DEFAULT_GRADIENT_COLOR,
          contrastValue,
          contrastRatio
        )
    )
  ) {
    const fallbackColor = isColorDark(contrastValue) ? '#FFFFFF' : '#000000'
    // If any color is not readable, fall back from gradient to solid black or white
    return {
      ...gradient,
      colors: [fallbackColor, fallbackColor], // CSS wont do a gradient of one color
    }
  }

  const readableColors = gradient.colors.map((c) =>
    makeColorReadable(
      c || DEFAULT_GRADIENT_COLOR,
      contrastValue,
      contrastRatio,
      whiteBlackOnly
    )
  )
  return {
    ...gradient,
    colors: readableColors,
  }
}

export const lightenedGradient = (
  gradient: LinearGradient | undefined,
  lightness: number
): LinearGradient | undefined => {
  if (!gradient) return undefined
  const lightenedColors = gradient.colors.map((c) =>
    colorWithLightness(c || DEFAULT_GRADIENT_COLOR, lightness)
  )
  return {
    ...gradient,
    colors: lightenedColors,
  }
}
