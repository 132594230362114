import { CSSObject } from '@emotion/react'
import { User } from '@gammatech/schema'
import React from 'react'

import { Font, ThemeFont } from 'modules/api'
import { MaybeHex } from 'modules/theming/components/ColorPickerMenu/types'
import { ColorAttrs } from 'modules/tiptap_editor/components/panels/ColorAttrs'
import { ImageShape } from 'modules/tiptap_editor/extensions/SmartLayout/options/types'
import {
  BackgroundOptions,
  ContainerOptions,
} from 'modules/tiptap_editor/styles/types'

import { LinearGradient } from './components/LinearGradientPicker/types'

export type ThemeType = 'custom' | 'standard' | 'archived'

export type Theme = {
  id: string
  workspaceId?: string
  name: string
  archived: boolean
  createdBy?: Partial<
    Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  >
  createdTime?: string
  updatedTime?: string
  priority: number
  // Styles
  headingFont?: string
  headingFontWeight?: number
  bodyFont?: string
  bodyFontWeight?: number
  accentColor?: string
  logoUrl?: string
  // Advanced
  config: {
    // Todo: move to top level
    themeBase?: string
    // Deck backgrounds
    background?: BackgroundOptions // [existing] The deck background. Only visible in doc mode.

    // Colors, in addition to accent color
    headingColor?: string // [existing]
    bodyColor?: string // [existing]
    secondaryAccentColors?: MaybeHex[] // [new] Optional secondary colors in addition to the accent color. An ordered list.
    headingGradient?: LinearGradient // [new]
    accentGradient?: LinearGradient // [new]
    disableReadabilityAdjustment?: boolean // [new] Disable the automatic adjustment of text color to improve readability

    // Fonts
    fontSize?: number // [existing] A multiplier on the font size, defaults to 1

    // Card styles
    containerStyles?: CSSObject // [existing] CSS applied to the card container. Extends boxStyles, interactiveBoxStyles
    container?: ContainerOptions // [existing] isDark and width are still needed in new world. Maybe move these out to top level props?

    cardBackground?: BackgroundOptions // [new] The default background color for the card body.
    accentBackgrounds?: BackgroundOptions[] // [new] A set of splashy backgrounds for card layouts. New cards will cycle through these, if provided
    accentBackgroundsColorize?: BackgroundOptions
    contentStyles?: Record<string, any> // [existing] Was mainly used for heading gradients. Should not be needed anymore.

    // Currently only used for AI theme suggestions
    keywords?: {
      color: string[]
      tone: string[]
    }

    // Deprecated
    colors?: ColorAttrs[] // [existing] Optional secondary colors in addition to the accent color. An ordered list.
  }
  fonts?: ThemeFont[]
  docCount?: number
}

export type ThemeBase = {
  key: string
  name: () => string
  description?: () => string
  cardSx: ThemeCSSObject
  cardFullBleedSx?: ThemeCSSObject
  boxSx?: ThemeCSSObject //
  imageSx?: ThemeCSSObject // Images, media placeholder
  clickableSx?: ThemeCSSObject &
    Partial<{
      '&.button': ThemeCSSObject
    }> // Nested cards, embeds/videos, buttons

  // For smart layouts and diagrams
  outsideTextSx?: CSSObject // Font effects for text around shapes - like timeline labels, image captions, pyramid outside text
  boxBackgroundSx?: CSSObject // Background for boxes like bullet markers, staircase steps that can have content over them
  shapeBackgroundSx?: CSSObject // Background for SVG shapes like pyramid steps, arrows, circle stats that can have content over them
  boxFillSx?: CSSObject // Foreground for filled boxes like bar stats
  shapeFillSx?: CSSObject // Foreground for filled SVG shapes like circle stats
  lineSx?: CSSObject // For lines like on timeline, diagram connectors,
  hasTransparentShapes?: boolean // If this is true, don't adjust the color of content inside smart layouts when the color is overridden
  smartLayoutImageSx?: CSSObject // Styles applied to smart layout images
  smartLayoutImageShape?: ImageShape // The default shape of images in smart layouts

  tableSx?: CSSObject // Styles applied to tables
  SVGDefs?: React.FC<{
    theme: Theme
  }>
  ornaments?: ThemeOrnament[]
  imageMasks?: any[]
  getCSSVars: (props: {
    cardColor: string
    accentColor: string
    accentPalette: string[]
  }) => CSSObject & { '--line-thickness': string }
  tldrawStyles?: {
    fill: 'semi' | 'solid' | 'none'
    strokeWidth: 'none' | 'sm' | 'md' | 'lg'
  }
}

type ThemeCSSObject = Partial<
  Pick<
    CSSObject,
    | 'borderRadius'
    | 'border'
    | 'boxShadow'
    | 'backgroundColor'
    | '@media print'
    | 'backdropFilter' // should this be done via card background instead?
    | '_hover'
  >
>

export type ThemeOrnament = {
  key: string
  image: StaticImageData
}

export type FontMap = {
  [fontId: string]: Font
}

export type FontPickerFonts = {
  globalFonts: Font[]
  workspaceFonts: Font[]
}
