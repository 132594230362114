import { i18n } from '@lingui/core'

import { Currency } from 'modules/api/generated/graphql'
import { shouldShowCurrencyDisplayAsCode } from 'modules/monetization/utils'

import { isNavigatorLanguageGA } from './localeKey'
const getCurrencyDisplay = (
  currency?: Currency
): Intl.NumberFormatOptions['currencyDisplay'] => {
  if (shouldShowCurrencyDisplayAsCode(currency)) {
    return 'code'
  }
  return 'symbol'
}

export const formatCurrency = (
  amount: number,
  options?: Intl.NumberFormatOptions & { currency?: Currency }
) => {
  const navigatorLanguageGA = isNavigatorLanguageGA()
  const currency = options?.currency || Currency.Usd
  const currencyDisplay = getCurrencyDisplay(currency)
  const optionsToUse: Intl.NumberFormatOptions = {
    ...{
      style: 'currency',
      notation: 'compact',
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    },
    ...options,
    ...{
      currency, // never let currency be undefined
      currencyDisplay,
    },
  }

  // Uses the navigator.language to format the currency
  if (!navigatorLanguageGA) {
    return amount.toLocaleString(navigator.language, optionsToUse)
  }

  // Otherwise, uses the language set in the i18n provider
  return i18n.number(amount, optionsToUse)
}
