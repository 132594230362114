import { createSlice } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'

import { RootState } from 'modules/redux'

export type SuggestionVariant = {
  label: string
  id: string
  card: JSONContent // On edit, we could just update this value
  explanation?: string
  isOriginal?: boolean
}

type CardSuggestionsStatus = 'loading' | 'done' | 'error'

export type CardSuggestions = {
  status: CardSuggestionsStatus
  interactionId?: string
  loadingMessage?: string
  selected?: string // Which variant is selected
  variants: SuggestionVariant[] // All variants
}

export type CardSuggestionsMap = Record<
  string, // CardId
  CardSuggestions
>

export type CardSuggestionState = {
  cardSuggestionsMap: CardSuggestionsMap
}

const initialState: CardSuggestionState = {
  cardSuggestionsMap: {},
}

const AIVariationsSlice = createSlice({
  name: 'AIVariations',
  initialState,
  reducers: {
    reset: () => initialState,
    setCardSuggestionsStatus(
      state,
      action: {
        payload: {
          cardId: string
          status: CardSuggestionsStatus
        }
      }
    ) {
      const { cardId, status } = action.payload
      state.cardSuggestionsMap[cardId] ??= {
        variants: [],
        status,
      }
      state.cardSuggestionsMap[cardId].status = status
    },
    setCardSuggestions(
      state,
      action: {
        payload: {
          cardId: string
          suggestions: CardSuggestions
        }
      }
    ) {
      const { cardId, suggestions } = action.payload
      state.cardSuggestionsMap[cardId] = suggestions
    },
    removeCardSuggestions(
      state,
      action: {
        payload: {
          cardId: string
        }
      }
    ) {
      const { cardId } = action.payload
      delete state.cardSuggestionsMap[cardId]
    },
    addCardVariant(
      state,
      action: {
        payload: {
          cardId: string
          variant: SuggestionVariant
          autoselect?: boolean
        }
      }
    ) {
      const { cardId, variant, autoselect } = action.payload
      state.cardSuggestionsMap[cardId] ??= {
        variants: [],
        status: 'loading',
      }

      state.cardSuggestionsMap[cardId].variants.push(variant)

      if (autoselect) {
        state.cardSuggestionsMap[cardId].selected = variant.id
      }
    },
    addCardVariants(
      state,
      action: {
        payload: {
          cardId: string
          variant: SuggestionVariant
          autoselect?: boolean
        }[]
      }
    ) {
      const variants = action.payload
      variants.forEach(({ cardId, variant, autoselect }) => {
        state.cardSuggestionsMap[cardId] ??= {
          variants: [],
          status: 'done',
        }

        state.cardSuggestionsMap[cardId].variants.push(variant)

        if (autoselect) {
          state.cardSuggestionsMap[cardId].selected = variant.id
        }
      })
    },
    selectCardVariant(
      state,
      action: {
        payload: {
          cardId: string
          variantId: string
        }
      }
    ) {
      const { cardId, variantId } = action.payload
      const cardData = state.cardSuggestionsMap[cardId]
      if (cardData) {
        cardData.selected = variantId
      }
    },
  },
})

export const {
  reset,
  addCardVariants,
  selectCardVariant,
  setCardSuggestions,
  removeCardSuggestions,
} = AIVariationsSlice.actions

type AIVariationsSliceState = Pick<RootState, 'AIVariations'>

// Selectors

export const selectCardSuggestionsMap = (state: AIVariationsSliceState) =>
  state.AIVariations.cardSuggestionsMap

export const selectCardSuggestions =
  (cardId: string) => (state: AIVariationsSliceState) =>
    state.AIVariations.cardSuggestionsMap[cardId]

export const selectCardSuggestionsStatus =
  (cardId: string) => (state: AIVariationsSliceState) =>
    state.AIVariations.cardSuggestionsMap[cardId]?.status

export const selectSelectedCardVariant =
  (cardId: string) => (state: AIVariationsSliceState) => {
    const data = selectCardSuggestions(cardId)(state)
    if (!data) return null
    const { selected, variants } = data
    return variants.find((variant) => variant.id === selected)
  }

// Reducer
export const AIVariationsReducer = AIVariationsSlice.reducer
