import { BoxProps } from '@chakra-ui/layout'

import { getStore } from 'modules/redux'
import { Theme } from 'modules/theming'
import { selectEditable } from 'modules/tiptap_editor/reducer'
import { DEFAULT_THEME_BACKGROUND } from 'modules/tiptap_editor/styles/constants'
import {
  BackgroundImageAttrs,
  BackgroundOptions,
  BackgroundType,
} from 'modules/tiptap_editor/styles/types'
import { isColorDark } from 'utils/color'
import {
  ImageResizeParams,
  backgroundImageFromUrls,
  resizeAndProxyImageUrl,
} from 'utils/image'

import { DEFAULT_MASK } from '../extensions/Card/Card2/BackgroundMask'

export const getDocOrThemeBackground = (
  theme: Theme,
  docBackground?: BackgroundOptions
): BackgroundOptions =>
  !docBackground || docBackground.type === BackgroundType.NONE
    ? !theme.config.background ||
      theme.config.background.type === BackgroundType.NONE
      ? DEFAULT_THEME_BACKGROUND
      : theme.config.background
    : docBackground

// Return true for dark, false for light, null for no mask or default color
// If useDefaultMask is true, it will use the default mask if none is provided
export const isBackgroundDark = (
  background: BackgroundOptions,
  useDefaultMask?: boolean
): boolean | null => {
  // If it's a color background, check if the color is dark
  if (background.type === BackgroundType.COLOR && background.color) {
    return background.color.isDark ?? isColorDark(background.color.hex)
  } else if (background.type === BackgroundType.GRADIENT) {
    return null // Gradients auto adapt to light/dark mode
  }

  const mask = background.mask || (useDefaultMask ? DEFAULT_MASK : null)
  if (!mask) {
    // Todo: check the average_color of the image
    return null
  }
  // If there's a mask applied, check if it's black or white
  switch (mask.color) {
    case 'black':
      return true
    case 'white':
      return false
    default:
      return null
  }
}

export const getBackgroundPosFromBackgroundImageAttrs = (
  attrs: BackgroundImageAttrs | undefined | null
): string => {
  if (!attrs) {
    return 'center'
  }
  const { backgroundPos } = attrs
  return backgroundPos ? `${backgroundPos.x}% ${backgroundPos.y}%` : 'center'
}

export const getBackgroundProps = (
  background: BackgroundOptions,
  isDark: boolean,
  resizeParams: Partial<ImageResizeParams> = { width: 2400 }
): BoxProps => {
  const isEditable = selectEditable(getStore().getState())
  if (background.type === BackgroundType.GRADIENT) {
    const css = background.gradient?.css || background.css
    return {
      backgroundColor: isDark ? 'black' : 'white',
      ...css,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    }
  }

  if (background.type === BackgroundType.IMAGE && background.image) {
    const { src, tempUrl, meta } = background.image
    const resizedSrc = !src
      ? undefined
      : resizeAndProxyImageUrl(src, resizeParams, meta)

    const backgroundImage = backgroundImageFromUrls(
      resizedSrc,
      // Use the tempUrl if image is uploading in editing mode. This prevents a flash of no-image.
      isEditable ? tempUrl : undefined
    )
    const backgroundPosition = getBackgroundPosFromBackgroundImageAttrs(
      background.image
    )

    return {
      backgroundImage,
      backgroundPosition,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    }
  }

  if (background.type === BackgroundType.COLOR && background.color) {
    return {
      // Legacy backgrounds had color specified in color field directly,
      // new ones should all be in color.hex
      backgroundColor:
        typeof background.color === 'string'
          ? background.color
          : background.color.hex,
    }
  }

  return {}
}

export const getBackgroundColor = (
  background?: BackgroundOptions
): string | undefined => {
  if (
    background &&
    background.type === BackgroundType.COLOR &&
    background.color
  ) {
    return background.color.hex
  } else {
    return
  }
}
