import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

import { GraphqlUser } from './context/UserContext'

export type UserState = {
  user?: GraphqlUser
  currentWorkspaceId?: string
}

const initialState: UserState = {
  user: undefined,
  currentWorkspaceId: undefined,
}

const UserSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    reset: () => initialState,
    setUser(
      state: UserState,
      action: PayloadAction<{
        user?: GraphqlUser
      }>
    ) {
      const { user } = action.payload
      state.user = user
    },
    setCurrentWorkspaceId(
      state: UserState,
      action: PayloadAction<{
        currentWorkspaceId?: string
      }>
    ) {
      const { currentWorkspaceId } = action.payload
      state.currentWorkspaceId = currentWorkspaceId
    },
  },
})

export const { setUser, setCurrentWorkspaceId, reset } = UserSlice.actions

type SliceState = Pick<RootState, 'User'>

// Selectors
export const selectUser = (state: SliceState) => state.User.user

export const selectUserLocale = (state: SliceState) => {
  const user = selectUser(state)
  return user?.settings?.locale
}

export const selectCurrentWorkspace = (state: SliceState) => {
  const user = selectUser(state)
  const currentWorkspaceId = selectCurrentWorkspaceId(state)
  return user?.workspaceMemberships?.find(
    (membership) => membership.workspace?.id === currentWorkspaceId
  )?.workspace
}

export const selectCurrentWorkspaceId = (state: SliceState) =>
  state.User.currentWorkspaceId

// Reducer
export const UserReducer = UserSlice.reducer
