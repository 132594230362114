import { CSSObject } from '@emotion/react'
import { merge } from 'lodash'

import { getThemeCardColor, isThemeDark, Theme } from 'modules/theming'
import { getBoxStyles, getClickableStyles } from 'modules/theming/styles/box'
import {
  ContainerEffect,
  ContainerOptions,
} from 'modules/tiptap_editor/styles/types'
import { colorWithOpacity, lightenColor } from 'utils/color'

import { getBackgroundColor } from './backgroundStyles'

export const DEFAULT_CONTAINER = {
  isDark: undefined,
  effect: ContainerEffect.FROSTED,
}

const DEFAULT_SHADOW = 'var(--chakra-shadows-md)'
export const DEFAULT_LIGHT_CONTAINER_BACKGROUND = '#FFFFFF'
export const DEFAULT_DARK_CONTAINER_BACKGROUND = '#0C0C0C'

export const getContainerStyles = (
  container: ContainerOptions,
  theme: Theme,
  isMobileDevice: boolean
): CSSObject => {
  if (!container) return {}

  const DEFAULT_BORDER_RADIUS = isMobileDevice
    ? 'var(--chakra-radii-md)'
    : 'var(--chakra-radii-xl)'

  const containerStyleOverrides = getContainerStyleOverrides(theme, container)
  const containerBackgroundColor = getContainerBackgroundColor(theme, container)
  const { isDark } = getContainerOptions(theme, container)

  switch (container.effect) {
    case ContainerEffect.COLLAPSED:
      return {
        ...containerStyleOverrides,
        backgroundColor: colorWithOpacity(
          isDark
            ? lightenColor(containerBackgroundColor, 30)
            : containerBackgroundColor,
          0.6
        ),
        '@media print': {
          boxShadow: 'none',
        },
      }
    case ContainerEffect.SOLID:
      return {
        boxShadow: DEFAULT_SHADOW,
        '--box-border-color': isDark
          ? 'var(--chakra-colors-gray-700)'
          : 'var(--chakra-colors-gray-200)',
        '--box-border-radius': DEFAULT_BORDER_RADIUS,
        ...containerStyleOverrides,
        backgroundColor: containerBackgroundColor,
        '@media print': {
          boxShadow: 'none',
        },
      }
    case ContainerEffect.FADED:
      return {
        boxShadow: DEFAULT_SHADOW,
        '--box-border-color': isDark
          ? 'var(--chakra-colors-gray-whiteAlpha-300)'
          : 'var(--chakra-colors-whiteAlpha-500)',
        '--box-border-radius': DEFAULT_BORDER_RADIUS,
        ...containerStyleOverrides,
        backgroundColor: colorWithOpacity(containerBackgroundColor, 0.75),
        '@media print': {
          boxShadow: 'none',
        },
      }
    case ContainerEffect.FROSTED:
      return {
        backdropFilter: `blur(20px) saturate(170%)`,
        boxShadow: DEFAULT_SHADOW,
        '--box-border-color': isDark
          ? 'var(--chakra-colors-gray-whiteAlpha-300)'
          : 'var(--chakra-colors-whiteAlpha-500)',
        '--box-border-radius': DEFAULT_BORDER_RADIUS,
        ...containerStyleOverrides,
        backgroundColor: colorWithOpacity(containerBackgroundColor, 0.75),
        '@media print': {
          boxShadow: 'none',
          backgroundColor: colorWithOpacity(containerBackgroundColor, 0.95),
        },
      }
    case ContainerEffect.CLEAR:
      return {
        boxShadow: undefined,
        borderRadius: containerStyleOverrides.borderRadius,
      }
  }

  return {}
}

// Reads the default container options from the theme,
// and considers any overrides from the card-level settings
// (e.g. dark/light, frosted/faded)
export const getContainerOptions = (
  theme: Theme,
  containerOptions?: Partial<ContainerOptions>
): ContainerOptions => {
  return merge({}, DEFAULT_CONTAINER, theme.config.container, containerOptions)
}

// Only used in Card1, deprecated in Card2
export const getContainerStyleOverrides = (
  theme: Theme,
  containerOptions?: ContainerOptions
) => {
  const { config } = theme
  const container = getContainerOptions(theme, containerOptions)
  const containerStyles = {
    ...getBoxStyles(theme),
    ...(container.effect == ContainerEffect.COLLAPSED
      ? getClickableStyles(theme, false)
      : config.containerStyles),
  }
  if (container.isDark !== isThemeDark(theme)) {
    // Fall back to the default background if the card overrides the theme's light/dark setting
    return {
      ...containerStyles,
      backgroundColor: undefined,
    }
  }
  return containerStyles
}

export const getContainerBackgroundColor = (
  theme: Theme,
  containerOptions?: ContainerOptions
) => {
  const container = getContainerOptions(theme, containerOptions)
  const themeCardColor = getThemeCardColor(theme)

  // In the future, we might allow background types other than color within the container.
  // But for now, it's only color
  const containerColor = getBackgroundColor(container.background)

  return (
    containerColor ??
    themeCardColor ??
    (container.isDark
      ? DEFAULT_DARK_CONTAINER_BACKGROUND
      : DEFAULT_LIGHT_CONTAINER_BACKGROUND)
  )
}
