import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

export type FeatureFlagsState = {
  isDocWorkspaceSet: boolean
}

const initialState: FeatureFlagsState = {
  isDocWorkspaceSet: false,
}

const FeatureFlagsSlice = createSlice({
  name: 'FeatureFlags',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsDocWorkspaceSet(
      state: FeatureFlagsState,
      action: PayloadAction<{
        isDocWorkspaceSet: boolean
      }>
    ) {
      const { isDocWorkspaceSet } = action.payload
      state.isDocWorkspaceSet = isDocWorkspaceSet
    },
  },
})

export const { setIsDocWorkspaceSet } = FeatureFlagsSlice.actions

type SliceState = Pick<RootState, 'FeatureFlags'>

// Selectors
export const selectIsDocWorkspaceSet = (state: SliceState): boolean => {
  return state.FeatureFlags.isDocWorkspaceSet
}

// Reducer
export const reducer = FeatureFlagsSlice.reducer
