import { uniq } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

import { getBackgroundColor } from 'modules/tiptap_editor/styles/backgroundStyles'
import { DEFAULT_THEME_BACKGROUND } from 'modules/tiptap_editor/styles/constants'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'
import { filterNotVoid } from 'utils/array'
import { isColorDark } from 'utils/color'

import {
  DEFAULT_ACCENT_COLOR,
  DEFAULT_BODY_COLOR,
  DEFAULT_THEME_ID,
  EMPTY_THEME,
  getEmptyThemeName,
  DEFAULT_BODY_COLOR_DARK,
  DEFAULT_HEADING_COLOR,
  DEFAULT_HEADING_COLOR_DARK,
} from '../constants'
import { Theme } from '../types'

export const themeSortFunction = (a: Theme, b: Theme) => {
  const regex = new RegExp(/\p{Emoji_Presentation}/, 'gu')
  const nameA = a.name.replace(regex, '').trim()
  const nameB = b.name.replace(regex, '').trim()

  return (
    // sort by priority first (global themes only), then sort by name (ignoring punctuation/emoji)
    (!a.workspaceId && !b.workspaceId ? b.priority - a.priority : 0) ||
    nameA.localeCompare(nameB, 'en-US', { ignorePunctuation: true })
  )
}

export const getThemeBackgroundOrDefault = (theme: Theme) =>
  theme.config?.background &&
  theme.config.background.type !== BackgroundType.NONE
    ? theme.config.background
    : DEFAULT_THEME_BACKGROUND

export const isThemeDark = (theme: Theme) => {
  const cardColor = getThemeCardColor(theme)
  return cardColor
    ? isColorDark(cardColor)
    : theme.config.container?.isDark ?? false
}

export const getAccentColor = (theme: Theme) =>
  theme.accentColor ?? DEFAULT_ACCENT_COLOR

// Get the first accent image, if any
export const getFirstAccentBackground = (theme: Theme) =>
  theme.config?.accentBackgrounds?.[0] ?? undefined

export const getAccentColorPalette = (theme: Theme) => {
  const accentColor = getAccentColor(theme)
  const secondaryColors = filterNotVoid(
    theme.config.secondaryAccentColors || []
  )
  const palette = [accentColor].concat(secondaryColors)
  return palette
}

export const getThemeColorPalette = (theme: Theme) => {
  const accentColors = getAccentColorPalette(theme)
  const cardColor = getThemeCardColor(theme)
  const isDark = isThemeDark(theme)
  const {
    bodyColor = isDark ? DEFAULT_BODY_COLOR_DARK : DEFAULT_BODY_COLOR,
    headingColor = isDark ? DEFAULT_HEADING_COLOR_DARK : DEFAULT_HEADING_COLOR,
  } = theme.config
  return uniq(
    filterNotVoid([...accentColors, cardColor, bodyColor, headingColor])
  )
}

export const getThemeCardColor = (theme: Theme): string | undefined => {
  const { cardBackground } = theme.config
  return getBackgroundColor(cardBackground)
}

export const makeCleanCustomTheme = (theme: Theme, resetId = false): Theme => {
  const {
    // @ts-ignore
    __typename: _typename,
    createdBy: _createdBy,
    workspaceId: _workspaceId,
    createdTime: _createdTime,
    updatedTime: _updatedTime,
    ...rest
  } = theme

  const newTheme = {
    ...cloneDeep(rest),
    priority: 0,
    archived: false,
    name: getEmptyThemeName(),
  }
  if (resetId && newTheme?.id) {
    newTheme.id = 'new'
  }

  return newTheme
}

export const getDefaultNewTheme = (themes?: Theme[]): Theme => {
  const preferredTheme =
    themes?.find((t) => t.id === DEFAULT_THEME_ID) || themes?.[0]
  if (preferredTheme) {
    return makeCleanCustomTheme(preferredTheme)
  } else {
    return EMPTY_THEME
  }
}

export const getThemeStyleDescription = (theme: Theme): string | undefined => {
  const keywords = theme.config.keywords
  if (!keywords) return

  const colorDescription = keywords.color.slice(0, 6).join(' ')
  const toneDescription = keywords.tone.slice(0, 6).join(' ')

  return `${colorDescription}, ${toneDescription}`
}
