export type KeyDownEvents = 'keydown' | 'keyup'

export type EventScopes =
  | 'GLOBAL_SEARCH'
  | 'ZOOMABLE_OVERLAY'
  | 'SLIDE_VIEW'
  | 'NAVIGATION_CONTROLS'
  | 'PRESENT_BLOCK'
  | 'DOC_BLUR'
  | 'TABLE_OF_CONTENTS'
  | 'FILMSTRIP'
  | 'COMMENT_FEED_PANEL'
  | 'DESIGN_PARTNER_PANEL'
  | 'CARD_NOTES_PANEL'
  | 'CLIPPABLE'
  | 'SITE_PREVIEW_PANE'
  | 'PRESENTER_VIEW'

export const PriorityOrder: EventScopes[] = [
  // For the global search component
  'GLOBAL_SEARCH',

  // For zoomed in embeds
  'ZOOMABLE_OVERLAY',

  // For escape from editing in slide view
  'SLIDE_VIEW',

  'COMMENT_FEED_PANEL',

  'DESIGN_PARTNER_PANEL',

  'CARD_NOTES_PANEL',

  // For Arrow Keys in the editor
  'NAVIGATION_CONTROLS',

  // For enter/leave present mode
  'PRESENT_BLOCK',

  // Toggle TOC open/closed
  'TABLE_OF_CONTENTS',

  // Filmstrip navigation
  'FILMSTRIP',

  // Blur the editor on esc
  'DOC_BLUR',

  'SITE_PREVIEW_PANE',

  'PRESENTER_VIEW',
]

type Handler = {
  scope: EventScopes
  fn: (event: KeyboardEvent) => boolean | undefined
}

type UnsubscribeFn = () => void

export class KeyboardHandler {
  protected listeners: {
    [s in KeyDownEvents]: Handler[]
  } = {
    keydown: [],
    keyup: [],
  }

  handleKeydown = (event: KeyboardEvent) => this.handleEvent('keydown', event)

  private handleKeyup = (event: KeyboardEvent) =>
    this.handleEvent('keyup', event)

  private handleEvent<K extends KeyDownEvents>(
    key: K,
    event: KeyboardEvent
  ): boolean {
    // Prevent running when ProseMirror handles and/or captures an event
    // In these cases, ProseMirror will preventDefault
    // https://github.com/ProseMirror/prosemirror-view/blob/67581c4d090141ac8fc135a2c0281f493ca038e3/src/input.ts#L124
    // Note that handleEvent may still be called in these cases via our Keyboard extension in tiptap_editor
    if (
      event.defaultPrevented &&
      event.target instanceof HTMLElement &&
      event.target.classList.contains('ProseMirror')
    ) {
      return false
    }
    for (const listener of this.listeners[key]) {
      const result = listener.fn(event)
      if (result === true) {
        console.debug('[KeyboardHandler].handleEvent - handled by:', listener)
        return true
      }
    }
    return false
  }

  initialize() {
    window.addEventListener('keydown', this.handleKeydown)
    window.addEventListener('keyup', this.handleKeyup)
  }

  reset() {
    window.removeEventListener('keydown', this.handleKeydown)
    window.removeEventListener('keyup', this.handleKeyup)
    this.listeners['keydown'] = []
    this.listeners['keyup'] = []
  }

  on<K extends KeyDownEvents>(
    key: K,
    scope: EventScopes,
    fn: Handler['fn']
  ): UnsubscribeFn {
    const handler = { scope, fn }

    this.listeners[key] = this.listeners[key] || []
    this.listeners[key].push(handler)
    this.listeners[key].sort((a, b) => {
      return PriorityOrder.indexOf(a.scope) - PriorityOrder.indexOf(b.scope)
    })

    return () => {
      const ind = this.listeners[key].indexOf(handler)
      if (ind > -1) {
        this.listeners[key].splice(ind, 1)
      }
    }
  }
}

export const keyboardHandler = new KeyboardHandler()
