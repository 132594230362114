import { getTextAmounts } from './constants'
import { TextAmount } from './types'
export const isContentEmpty = (contentMd: string) => {
  return contentMd.replaceAll('---', '').trim() === ''
}

export const countSectionDividers = (contentMd: string) => {
  const sections = contentMd.split('\n---\n')
  return sections.length
}

export const countContentParagraphs = (contentMd: string) => {
  const blocks = contentMd.split('\n\n')
  return blocks.length
}

export const findTextAmount = (len: TextAmount) => {
  const TEXT_AMOUNTS = getTextAmounts()
  return TEXT_AMOUNTS[len] || TEXT_AMOUNTS.md
}
