import { BoxProps } from '@chakra-ui/layout'

import { AnimationConfig } from 'modules/media/types/AnimationConfig'
import { ImageAttrs } from 'modules/media/types/Image'
import { VibeGradient } from 'modules/theming/components/MeshGradientPicker/types'

import { ColorAttrs } from '../components/panels/ColorAttrs'

export type ContainerWidth = 'sm' | 'md' | 'lg' | 'full'

export type ContainerOptions = {
  isDark?: boolean
  effect?: ContainerEffect // [deprecated in card2]
  css?: BoxProps // [deprecated in card2] Theme overrides of built-in container effects
  width?: ContainerWidth // Only "lg" is used in card2
  background?: BackgroundOptions
}

export enum ContainerEffect {
  SOLID = 'solid',
  FROSTED = 'frosted',
  FADED = 'faded',
  CLEAR = 'clear',
  COLLAPSED = 'collapsed',
}

export type MaskOptions = {
  effect?: 'none' | 'faded' | 'frosted'
  color?: 'black' | 'white'
}

export type BackgroundImageAttrs = {
  backgroundPos?: BackgroundPos
} & ImageAttrs

export type BackgroundPos = {
  // these numbers are between 0-100 and represent background-position-x and background-position-y
  x: number
  y: number
}

export type BackgroundOptions = {
  type: BackgroundType // eg "image"
  source?: string // eg "image.unsplash"
  originalSource?: string // eg "image.unsplash" - the original source, to be used by the theme editor for accent images
  accentId?: string
  inside?: boolean
  // Images
  image?: BackgroundImageAttrs
  // Animations
  animation?: AnimationConfig
  // Colors
  color?: ColorAttrs
  // Gradients
  gradient?: VibeGradient
  // Legacy/custom
  css?: BoxProps
  mask?: MaskOptions
}

export enum BackgroundType {
  ANIMATION = 'animation',
  IMAGE = 'image',
  COLOR = 'color',
  GRADIENT = 'gradient',
  NONE = 'none',
}
