import getConfig from 'next/config'

const IS_CLIENT_SIDE = typeof window !== 'undefined'
const IS_USER_TESTING_SESSION = false // Set to true to enable user testing

type Config = {
  API_HOST: string
  APPLICATION_ENVIRONMENT: 'dev' | 'staging' | 'production'
  ASSETS_CDN_HOST: string
  CHURNKEY_APP_ID: string
  CHURNKEY_MODE: 'test' | 'live'
  CONTENTFUL_API_TOKEN: string
  CONTENTFUL_GRAPHQL_ENDPOINT: string
  DATADOG_CLIENT_TOKEN: string
  DEBUG_ENABLED: boolean
  EDITOR_ANIMATION_DEBUG_FACTOR?: number
  EMBED_PROXY_URL: string
  EVENT_TRACKING_ENDPOINT: string
  FRONTEND_URL: string
  GAMMA_E2E_USER: boolean
  GIT_SHA: string
  GOOGLE_DRIVE_IMPORT_CLIENT_ID: string
  GOOGLE_PROJECT_ID: string
  GOOGLE_API_KEY: string
  HIGHLIGHT_PROJECT_ID: string
  HONEYCOMB_API_KEY: string
  HONEYCOMB_DATASET: string
  INTERCOM_APP_ID: string
  IMAGE_WORKER_HOST: string
  IS_CLIENT_SIDE: boolean
  IS_USER_TESTING_SESSION: boolean
  KNOCK_FEED_ID: string
  KNOCK_PUBLIC_KEY: string
  LAUNCH_DARKLY_CLIENT_SIDE_ID: string
  MULTIPLAYER_WS_URL: string
  OG_IMAGE_URL: string
  SCHEMA_BUILD: boolean
  SLACK_CLIENT_ID: string
  SHARE_TOKEN: string
  TRANSLOADIT_AUTH_KEY: string
  TRANSLOADIT_IMAGE_TEMPLATE_ID: string
  TRANSLOADIT_FILE_TEMPLATE_ID: string
  TRANSLOADIT_DOC_IMPORT_TEMPLATE_ID: string
  TRANSLOADIT_THEME_IMAGE_TEMPLATE_ID: string
  TRANSLOADIT_PPT_IMPORT_TEMPLATE_ID: string
  VISITOR_ID_COOKIE_DOMAIN: string
  GAMMA_PUPPETEER_SERVICE: boolean
  NEXT_PUBLIC_GA_ID: string
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE: string
  NEXT_PUBLIC_VERCEL_URL: string
  RECAPTCHA_SCORING_KEY: string
  RECAPTCHA_CHECKBOX_KEY: string
  CANNY_APP_ID: string
}

const EDITOR_ANIMATION_DEBUG_FACTOR_QUERY = new URLSearchParams(
  IS_CLIENT_SIDE ? window.location.search : ''
).get('EDITOR_ANIMATION_DEBUG_FACTOR')

const EDITOR_ANIMATION_DEBUG_FACTOR =
  IS_CLIENT_SIDE && window['Cypress']
    ? 0 // Disable animations in Cypress tests
    : EDITOR_ANIMATION_DEBUG_FACTOR_QUERY !== null
    ? Number(EDITOR_ANIMATION_DEBUG_FACTOR_QUERY)
    : null

// Security through obscurity. The cookie value here is gammadebug=gtpwtw
const DEBUG_ENABLED =
  IS_CLIENT_SIDE &&
  document.cookie.includes('g' + 'a' + 'm' + 'm' + 'adebug=' + 'gtp' + 'wtw')

const GAMMA_E2E_USER =
  IS_CLIENT_SIDE && document.cookie.includes('gamma_e2e_user=true')

const SHARE_TOKEN =
  (IS_CLIENT_SIDE &&
    new URLSearchParams(window.location.search).get('token')) ||
  ''

const GAMMA_PUPPETEER_SERVICE = IS_CLIENT_SIDE && window.gammaPuppeteerService

export const config: Config = {
  ...getConfig()?.publicRuntimeConfig,
  DEBUG_ENABLED,
  EDITOR_ANIMATION_DEBUG_FACTOR,
  GAMMA_E2E_USER,
  IS_CLIENT_SIDE,
  IS_USER_TESTING_SESSION,
  OG_IMAGE_URL: 'https://cdn.gamma.app/_app_static/images/og-image-d666e6.jpg',
  SCHEMA_BUILD: process.env.SCHEMA_BUILD === 'true',
  SHARE_TOKEN,
  GAMMA_PUPPETEER_SERVICE,
}

export function setConfigValue<T extends keyof Config>(
  key: T,
  value: Config[T]
) {
  config[key] = value
}
