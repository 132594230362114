/**
 * This file provides certain core info to subcomponents via context
 */
import * as Sentry from '@sentry/browser'
import jsCookie from 'js-cookie'
import { customAlphabet } from 'nanoid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import tinycolor from 'tinycolor2'
import * as workerTimers from 'worker-timers'

import { config } from 'config'
import {
  getWsLink,
  JsonPatch,
  setReactQueryErrorFilter,
  useGetUser,
  User,
  usersApi,
  useUpdateUserSettingsMutation,
} from 'modules/api'
import type {
  Organization as GraphqlOrganization,
  WorkspaceMembership as GraphqlWorkspaceMembership,
} from 'modules/api/generated/graphql'
import { cannyIdentify } from 'modules/canny/fns'
import { useRefreshCredits } from 'modules/credits/hooks'
import {
  getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback,
  isNavigatorLanguageGA,
} from 'modules/i18n/utils/localeKey'
import { updateIntercomUser } from 'modules/intercom'
import { getStore, useAppDispatch } from 'modules/redux'
import { useShouldUsePublishedVersion } from 'modules/sites/PublishingContext'
import {
  selectCurrentWorkspace,
  selectCurrentWorkspaceId,
  selectUser,
  setCurrentWorkspaceId as setCurrentWorkspaceIdRedux,
  setUser as setUserRedux,
} from 'modules/user/reducer'
import { EventEmitter } from 'utils/EventEmitter'
import { generateColor, generateName } from 'utils/generators'
import { useWakeUpDetector } from 'utils/hooks'
import { useLocalStorage } from 'utils/hooks/useLocalStorage'
import { getExistingQueryParams } from 'utils/url'

import { AbilityContext, abilityFactory } from './AuthContext'
import {
  GraphqlUser,
  UserContext,
  UserContextType,
  UserSetting,
  UserSettings,
} from './UserContext'

export * from './AuthContext'
export * from './UserContext'

const RETRY_COUNT = 2
const VISITOR_ID_COOKIE = 'gamma_visitor_id'
const VISITOR_COOKIE_EXPIRATION_DAYS = 10 * 365 // 10 years
export const WORKSPACE_SETTINGS_QUERY_PARAM = 'workspace-settings'

// Expose the current workspace id to non-react consumers
export const getCurrentWorkspaceId = () => {
  const state = getStore().getState()
  return selectCurrentWorkspaceId(state)
}

export const getCurrentUser = () => {
  const state = getStore().getState()
  return selectUser(state)
}

export const getCurrentWorkspace = () => {
  const state = getStore().getState()
  return selectCurrentWorkspace(state)
}

const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 15)

const refetchUser = () =>
  usersApi.getUser().then(
    () => {
      console.debug('[UserContextProvider][refreshInterval] User re-fetched.')
    },
    () => {} // No Gamma User
  )

export const generateSettingsUpdatePatch = (settings: {
  set?: Partial<UserSettings>
  remove?: UserSetting[]
}): JsonPatch[] => {
  const replacements: JsonPatch[] = Object.keys(settings.set || {}).map(
    (key) => {
      return {
        op: 'replace',
        path: '/' + key,
        value: settings.set?.[key],
      } as JsonPatch
    }
  )
  const removals: JsonPatch[] = (settings.remove || []).map((key) => {
    return {
      op: 'remove',
      path: '/' + key,
    } as JsonPatch
  })
  return [...replacements, ...removals]
}

type UserContextProps = {
  children: React.ReactNode
}

/**
 * Ensure there is a user id set in the cookie,
 * creating a new one if necessary, and return it
 */
const ensureCookieUserId = (): string => {
  const id = jsCookie.get(VISITOR_ID_COOKIE) || undefined
  if (id) return id
  const newId = nanoid()
  jsCookie.set(VISITOR_ID_COOKIE, newId, {
    domain: config.VISITOR_ID_COOKIE_DOMAIN,
    expires: VISITOR_COOKIE_EXPIRATION_DAYS,
    // this cookie needs to be sameSite=none in order for all domains to see it
    // (including custom versions or custom domains)
    sameSite: 'none',
    // it has to be secure for sameSite=none to stick in Chrome
    secure: true,
  })
  return newId
}

const getAnonymousUser = (id: string) => {
  return {
    id,
    displayName: id ? generateName(id) : '',
  }
}

/**
 * Basic event emitter for handling when user is signed in
 */

type UserSignedInEvent = {
  signedIn: boolean
}

export const eventEmitter = new EventEmitter<UserSignedInEvent>()

/**
 * Hook that listens for signedIn events and invokes passed-in callback
 */

export const useUserSignedIn = (callback: () => void) => {
  useEffect(() => {
    return eventEmitter.on('signedIn', callback)
  }, [callback])
}

const getColorObject = (id?: string) => {
  const colorValue = id ? generateColor(id) : '#cccccc'
  return {
    value: colorValue,
    isDark: tinycolor(colorValue).isDark(),
  }
}

// A 401/403 error means we know this user is not signed in
const isExpected4xxResponse = (r: Response) => [401, 403].includes(r.status)

export const UserContextProvider = ({
  children,
}: UserContextProps): JSX.Element => {
  const shouldUsePublishedVersion = useShouldUsePublishedVersion()
  useEffect(() => {
    // This is an annoying way to prevent ReactQuery from console.erroring 4xx responses
    setReactQueryErrorFilter((e) => {
      if (config.GAMMA_PUPPETEER_SERVICE) {
        return true
      }
      if (
        e instanceof Response &&
        isExpected4xxResponse(e) &&
        e.url.includes('/user')
      ) {
        return true
      }
      return false
    })
  }, [])

  const {
    data: userData,
    refetch,
    error,
    status: fetchStatus,
  } = useGetUser({
    retry: (count: number, resp: Response) => {
      // Puppeteer mock responses dont have a status code for some reason, but we dont want to retry them anyway
      // See https://linear.app/gamma-app/issue/G-2785/figure-out-how-to-properly-mock-user-with-puppeteer
      if (config.GAMMA_PUPPETEER_SERVICE || isExpected4xxResponse(resp)) {
        return false
      }

      // Retry other error codes
      return count <= RETRY_COUNT
    },
    enabled: !shouldUsePublishedVersion,
  })

  const user = useMemo<GraphqlUser | undefined>(() => {
    if (userData) {
      const { organizations, workspaceMemberships, ...rest } = userData

      const _organizations = organizations?.map((o) => {
        return { ...o, id: o.id as string, __typename: 'Organization' as const }
      })

      const _workspaceMemberships = workspaceMemberships?.map((m) => {
        return {
          ...m,
          workspace: {
            ...m.workspace,
            __typename: 'Organization' as const,
          },
          __typename: 'WorkspaceMembership' as const,
        }
      }) as GraphqlWorkspaceMembership[]

      // Coerce the data returned from /user to match
      // the shape of user objects from the GraphQL API
      // This is the only place in the codebase we should have to deal
      // with the /user shape. All others should use GraphqlUser
      return {
        ...rest,
        organizations: _organizations,
        workspaceMemberships: _workspaceMemberships,
        __typename: 'User',
      }
    }
    return userData
  }, [userData])

  const isUserLoading = fetchStatus === 'loading'
  const userLoggedOut = fetchStatus === 'error' && isExpected4xxResponse(error)
  const userStatus =
    fetchStatus === 'loading'
      ? 'loading'
      : userData?.id
      ? 'loggedIn'
      : userLoggedOut
      ? 'loggedOut'
      : 'error'

  const lastConfirmedLoggedInStatus = useRef<string | null>(null)
  useEffect(() => {
    // Only run when the user goes from logged out to logged in after initial load
    if (
      lastConfirmedLoggedInStatus.current === 'loggedOut' &&
      userStatus === 'loggedIn'
    ) {
      console.log('[UserContext] User signed in after initial page load.')
      eventEmitter.emit('signedIn', true)
    }
    if (['loggedIn', 'loggedOut'].includes(userStatus)) {
      lastConfirmedLoggedInStatus.current = userStatus
    }
  }, [userStatus])

  useUserSignedIn(
    useCallback(() => {
      // This is a hack to reconnect the subscription when a user is signed in
      // https://github.com/apollographql/subscriptions-transport-ws/issues/378
      // note that subscriptions-transport-ws is no longer maintained
      // but we can't upgrade until we upgrade apollo server
      if (!getWsLink) return
      try {
        // @ts-ignore: Property 'subscriptionClient' is private and only accessible within class 'WebSocketLink'.ts(2341)
        getWsLink().subscriptionClient.close(false, false)
      } catch (e) {
        console.error('Subscription reconnect failed', e)
      }
    }, [])
  )

  const [updateUserSettings] = useUpdateUserSettingsMutation()
  const setSettings = useCallback(
    async (settings: {
      set?: Partial<UserSettings>
      remove?: UserSetting[]
    }) => {
      const patch = generateSettingsUpdatePatch(settings)
      await updateUserSettings({
        variables: {
          patch,
        },
      })
        .then(() => {
          return refetch?.()
        })
        .catch((e) => {
          console.error(
            `[UpdateSettings] Error updating user settings for "${user?.id}"`,
            e.message
          )
        })
    },
    [updateUserSettings, refetch, user?.id]
  )

  const [currentWorkspaceId, setCurrentWorkspaceId] = useLocalStorage<
    string | undefined
  >('currentWorkspaceId', undefined)
  const currentWorkspace = useMemo<GraphqlOrganization | undefined>(() => {
    if (!user) return undefined

    const workspaces = user.workspaceMemberships?.map((m) => m.workspace)
    if (!workspaces || workspaces.length === 0) return undefined

    const queryParamWorkspaceId =
      getExistingQueryParams()[WORKSPACE_SETTINGS_QUERY_PARAM]
    const queryParamWorkspace = workspaces.find(
      (w) => w?.id === queryParamWorkspaceId
    )

    if (queryParamWorkspace) {
      setCurrentWorkspaceId(queryParamWorkspace.id)
      return queryParamWorkspace
    }

    const workspace = workspaces.find((w) => w?.id === currentWorkspaceId)
    if (!workspace) {
      // set the currentWorkspaceId to the first workspace if it isn't set or is invalid
      const firstWorkspace = workspaces?.[0]
      if (firstWorkspace) setCurrentWorkspaceId(firstWorkspace.id)
    }

    return workspace
  }, [user, currentWorkspaceId, setCurrentWorkspaceId])

  const dispatch = useAppDispatch()
  useEffect(() => {
    dispatch(setCurrentWorkspaceIdRedux({ currentWorkspaceId }))
  }, [currentWorkspaceId, dispatch])

  useEffect(() => {
    dispatch(setUserRedux({ user }))
  }, [user, dispatch])

  const [contextState, setContextState] = useState<UserContextType>(() => {
    // Set the anonymous user object based on userId
    const id = ensureCookieUserId()
    const anonymousUser = getAnonymousUser(id)
    return {
      anonymousUser,
      isUserLoading,
      userStatus: 'loading',
      isGammaOrgUser: false,
      color: getColorObject(id),
    }
  })
  const [userAbility, setUserAbility] = useState(
    abilityFactory.createForUser(user as User, config.SHARE_TOKEN)
  )

  useEffect(() => {
    if (!config.IS_CLIENT_SIDE || fetchStatus !== 'success' || !user) return
    updateIntercomUser({ user, currentWorkspace })
    cannyIdentify({ user })
  }, [fetchStatus, user, currentWorkspace])

  // Setup intervals to refresh the session cookie
  useEffect(() => {
    // Refresh the token every so often
    // Should align with server/src/identity/jwt.strategy.ts:JwtStrategy.validate
    const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 15
    const refreshTokenInterval = workerTimers.setInterval(
      refetchUser,
      REFRESH_TOKEN_INTERVAL
    )
    return () => workerTimers.clearInterval(refreshTokenInterval)
  }, [])
  useRefreshCredits(contextState.user, contextState.currentWorkspace?.id)

  // Also refresh the session cookie when we wake up
  useWakeUpDetector(refetchUser)

  useEffect(() => {
    console.debug('[UserContextProvider] user or isUserLoading changed.', {
      user,
      isUserLoading,
    })
    if (isUserLoading) return

    setContextState((prev) => {
      // Clear the currently set user
      Sentry.configureScope((scope) => scope.setUser(null))

      if (!user) {
        Sentry.setUser({
          anonymousUserId: prev?.anonymousUser?.id,
          anonymousUserDisplayName: prev?.anonymousUser?.displayName,
          isAnonymousUser: true,
        })

        return {
          ...prev,
          isUserLoading,
          userStatus,
          refetch,
          setCurrentWorkspaceId,
        }
      }
      // Finished loading user and confirmed has account
      Sentry.setUser({
        id: user?.id,
        orgId: currentWorkspace?.id,
      })
      return {
        ...prev,
        user,
        currentWorkspace,
        setCurrentWorkspaceId,
        isUserLoading,
        userStatus,
        isGammaOrgUser: user.email.endsWith('@gamma.app'),
        color: getColorObject(user.id),
        refetch,
        setSettings,
      }
    })
  }, [
    user,
    isUserLoading,
    userStatus,
    refetch,
    currentWorkspace,
    setCurrentWorkspaceId,
    setSettings,
  ])

  useEffect(() => {
    if (!user) return
    setUserAbility(
      abilityFactory.createForUser(user as User, config.SHARE_TOKEN)
    )
  }, [user])

  // Sync user.settings.locale to navigator.locale if it's not set yet
  useEffect(() => {
    if (!user || user?.settings?.locale) return

    if (isNavigatorLanguageGA()) {
      // Only set locale if it's a GA language
      setSettings?.({
        set: {
          locale: getSupportedLocaleKeyFromNavigatorLanguageOrUseFallback(),
        },
      })
    }
  }, [setSettings, user])

  return (
    <UserContext.Provider value={contextState}>
      <AbilityContext.Provider value={userAbility}>
        {children}
      </AbilityContext.Provider>
    </UserContext.Provider>
  )
}
