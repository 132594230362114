import { t } from '@lingui/macro'

import {
  brightenColor,
  colorWithOpacity,
  isColorDark,
  colorWithLightness,
} from 'utils/color'
import { isMobileDevice } from 'utils/deviceDetection'

import { resetHeadingStylesheet } from '../styles/heading'
import { ThemeBase } from '../types'

const BLOCK_BORDER_RADIUS = '0.375em'

export const DefaultThemeBase: ThemeBase = {
  key: 'default',
  name: () => t`Default`,
  description: () => t`Standard cards with rounded corners and subtle shadows`,
  getCSSVars({ cardColor, accentColor }) {
    const backgroundColor = cardColor
    const isDark = isColorDark(backgroundColor)
    const shapeBackgroundColor = isDark
      ? colorWithLightness(accentColor, 0.25)
      : colorWithLightness(accentColor, 0.9)
    const shapeBorderColor = isColorDark(shapeBackgroundColor)
      ? brightenColor(shapeBackgroundColor, 10)
      : brightenColor(shapeBackgroundColor, -10)

    return {
      // chakra's md shadow, sized in ems
      '--md-shadow':
        '0 0.25em 0.375em -0.0625em rgba(0, 0, 0, 0.1),0 0.125em 0.25em -0.0625em rgba(0, 0, 0, 0.06)',
      '--line-thickness': '0.2em',
      // Use a shadow for the border so it always goes outside the card and isn't covered by accent items
      '--card-border-color': isDark
        ? 'var(--chakra-colors-gray-700)'
        : 'var(--chakra-colors-gray-200)',
      '--card-border': `0.0625em solid var(--card-border-color)`,
      '--card-border-shadow': `0 0 0 0.0625em var(--card-border-color)`,
      '--clickable-background-color': colorWithOpacity(
        isDark ? brightenColor(cardColor, 20) : brightenColor(cardColor, -5),
        0.6
      ),
      '--box-border-radius': '0.375em',
      // The background for "background" shapes like text boxes
      '--shape-bg-color': shapeBackgroundColor,
      '--shape-border-color': shapeBorderColor,
      '--shape-bg-image': 'var(--accent-gradient-background)',
      '--shape-bg-image-svg': 'url(#theme-accent-background-gradient-linear)',
      '--shape-border-image-svg': 'url(#theme-accent-gradient-linear)',
      // The background for "fill" shapes like the percent of circle stats
      '--shape-fill': `var(--accent-gradient, var(--link-color))`,
      '--shape-fill-svg': `url(#theme-accent-gradient-linear) var(--link-color)`,
    }
  },
  boxSx: {
    border: '0.0625em solid var(--box-border-color)',
    borderRadius: 'var(--box-border-radius)',
  },
  clickableSx: {
    boxShadow: 'var(--md-shadow)',
    backgroundColor: 'var(--clickable-background-color)',
    '@media print': {
      boxShadow: 'none',
    },
  },
  cardSx: {
    boxShadow: 'var(--md-shadow), var(--card-border-shadow)',
    borderRadius: isMobileDevice() ? '0.375em' : '0.75em',
    backgroundColor: 'var(--card-color)',
    '@media print': {
      boxShadow: 'none',
      border: 'var(--card-border)',
    },
  },
  cardFullBleedSx: {
    backgroundColor: 'var(--card-color)',
  },
  imageSx: {
    borderRadius: BLOCK_BORDER_RADIUS,
  },
  boxBackgroundSx: {
    borderRadius: BLOCK_BORDER_RADIUS,
    background: `var(--shape-bg-color-override, var(--shape-bg-image, var(--shape-bg-color)))`,
    border:
      '0.0625em solid var(--shape-border-color-override, var(--shape-border-color))',
    // Reset headings to match body color to avoid visual noise
    ...resetHeadingStylesheet('var(--body-color)'),
  },
  outsideTextSx: {
    // Reset headings to match body color to avoid visual noise
    ...resetHeadingStylesheet('var(--body-color)'),
  },
  lineSx: {
    backgroundColor:
      'var(--shape-border-color-override, var(--shape-border-color))',
    borderRadius: BLOCK_BORDER_RADIUS,
  },
  boxFillSx: {
    background: `var(--shape-bg-color-override, var(--shape-fill))`,
  },
  shapeFillSx: {
    fill: `var(--shape-bg-color-override, var(--shape-fill-svg))`,
  },
  shapeBackgroundSx: {
    fill: 'var(--shape-bg-color-override, var(--shape-bg-image-svg) var(--shape-bg-color))',
    stroke:
      'var(--shape-border-color-override, var(--shape-border-image-svg) var(--shape-border-color))',
    strokeWidth: '0.0625em',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
  },
  tableSx: {
    border: '0.0625em solid var(--box-border-color)',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _even: {
        backgroundColor: 'blackAlpha.50',
      },
      _odd: {
        backgroundColor: 'whiteAlpha.50',
      },
    },

    td: {
      borderLeft: '0.0625em solid var(--shape-border-color)',
      _first: {
        borderLeftWidth: '0',
      },
    },
  },
}
