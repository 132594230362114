import { t } from '@lingui/macro'

import { getColorLightness, lightenColor } from 'utils/color'

import { ThemeBase } from '../types'
import { DefaultThemeBase } from './default'

export const IcebreakerThemeBase: ThemeBase = {
  ...DefaultThemeBase,
  key: 'icebreaker',
  name: () => t`Icebreaker`,
  description: () => t`Clean edges with professional monochrome colors`,
  getCSSVars(props) {
    const { cardColor } = props
    const backgroundLightness = getColorLightness(cardColor)
    return {
      ...DefaultThemeBase.getCSSVars(props),
      '--shape-bg-color': lightenColor(
        cardColor,
        // Icebreaker doesn't have borders, so we need the box to be a different
        // color to stand out from the card background. We generally darken,
        // unless it's super dark and then we lighten instead
        backgroundLightness > 0.1 ? -7 : 7
      ),
      '--shape-border-image-svg': 'url(#theme-accent-gradient-linear)',
      '--interactive-border': `0.0625em solid var(--accent-color-border)`,
      '--box-border-radius': '0.5em',
    }
  },
  boxSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
  },
  cardSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--card-color)',
  },
  cardFullBleedSx: {
    backgroundColor: 'var(--card-color)',
  },
  clickableSx: {
    border: 'var(--interactive-border)',
    backgroundColor: 'var(--shape-bg-color)',
  },
  boxBackgroundSx: {
    borderRadius: 'var(--box-border-radius)',
    backgroundColor: 'var(--shape-bg-color-override, var(--shape-bg-color))',
  },
  shapeBackgroundSx: {
    fill: 'var(--shape-bg-color-override, var(--shape-bg-color))',
    stroke:
      'var(--shape-border-color-override, var(--shape-border-image-svg) var(--shape-border-color))',
    strokeWidth: 0, // No stroke, unless you override it in diagram
  },
  lineSx: {
    backgroundColor:
      'var(--shape-border-color-override, var(--shape-border-color))',
  },
  outsideTextSx: {},
  tableSx: {
    border: '0px',
    borderRadius: 'var(--box-border-radius)',
    'tbody tr': {
      _odd: {
        backgroundColor: 'var(--shape-bg-color)',
      },
    },
  },
  tldrawStyles: {
    fill: 'solid',
    strokeWidth: 'none',
  },
}
