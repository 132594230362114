import { orderBy, sortBy } from 'lodash'
import parser from 'tld-extract'

import { config } from 'config'
import {
  DefaultDomainsFieldsFragment,
  Domain,
  GetDocQuery,
  GetSiteQuery,
  Route,
  Site,
  SiteFragmentFragment,
} from 'modules/api'

import { GAMMA_SITES_HOST, GAMMA_SITES_IPS } from './constants'

export const isSiteEnabled = (site?: Pick<Site, 'archived' | 'enabled'>) => {
  return Boolean(site && !site.archived && site.enabled)
}

export const isSiteLive = (site?: SiteFragmentFragment) => {
  const isSiteDeployed =
    site && site.currentDeployment && site.currentDeployment.deploymentTime

  // Site must be deployed and enabled to be considered live
  if (!isSiteDeployed || !isSiteEnabled(site)) {
    return false
  }

  const isSitePublished = site.routes.some((r) => isRoutePublished(r, site))
  return isSitePublished
}

export const isRoutePublished = (
  route: Site['routes'][0],
  site: SiteFragmentFragment // doc.site won't have route.doc, so this needs to be the main selectSite query
) => {
  const doc = route.doc
  if (!site.currentDeployment || !doc) return false

  return Boolean(
    doc &&
      !route.offline &&
      doc.publishedSnapshotId &&
      doc.publishedTime && // This makes it so that we don't prematurely show the "LIVE" badge
      new Date(site?.currentDeployment.deploymentTime) >
        new Date(doc.publishedTime)
  )
}

export const routeHasUnpublishedChanges = (
  route: Site['routes'][0],
  site: SiteFragmentFragment
) => {
  const doc = route.doc
  if (!site.currentDeployment || !doc || route.offline) return false

  if (!doc.publishedSnapshotId) {
    return true
  }

  return doc.currentSnapshotId !== doc.publishedSnapshotId
}

export const isPageLive = (
  doc: Site['routes'][0]['doc'] | GetDocQuery['doc'],
  site: SiteFragmentFragment
) => {
  if (!doc) return false
  const route = site.routes.find((r) => r.docId === doc.id)
  return isSiteLive(site) && route && isRoutePublished(route, site)
}

// Inspired by https://stackoverflow.com/a/26093611
export const isValidHostNameSyntax = (host?: string) =>
  Boolean(
    host?.match(/[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/)
  )

export const verifyDomain = async ({
  hostname,
  times = 20,
}: {
  times?: number
  hostname: string
}) => {
  let count = times
  return new Promise<boolean>((resolve, reject) => {
    const doCheck = async () => {
      count--
      const response = await fetch(
        `https://cloudflare-dns.com/dns-query?name=${hostname}`,
        {
          headers: {
            accept: 'application/dns-json',
          },
        }
      )
      const data = await response.json()
      console.log(`[verifyDomain] Verifying DNS for ${hostname}:`, data)
      const isVerified = data?.Answer?.some(
        (answer) =>
          (answer.type === 1 && GAMMA_SITES_IPS.includes(answer.data)) || // A record
          (answer.type === 5 && answer.data === GAMMA_SITES_HOST + '.') // CNAME record (trailing dot is required???)
      )
      if (isVerified) {
        resolve(true)
      } else if (count > 0) {
        setTimeout(doCheck, 1000)
      } else {
        reject()
      }
    }
    doCheck()
  })
}

export const getDnsRecords = (requestedUrl: string) => {
  const url = requestedUrl.startsWith('http')
    ? requestedUrl
    : `https://${requestedUrl}`
  const { domain, sub: subdomain } = parser(url)
  const isRootDomain = subdomain === '' || subdomain === 'www'

  const aRecords = GAMMA_SITES_IPS.map((ip) => {
    return {
      type: 'A',
      host: '@',
      value: ip,
      ttl: 300,
    }
  })

  const dnsRecords = isRootDomain
    ? [
        ...aRecords,
        {
          type: 'CNAME',
          host: 'www',
          value: GAMMA_SITES_HOST,
          ttl: 300,
        },
      ]
    : [
        {
          type: 'CNAME',
          host: subdomain,
          value: GAMMA_SITES_HOST,
          ttl: 300,
        },
      ]

  return {
    domain,
    subdomain: subdomain || 'www',
    dnsRecords,
    isRootDomain,
  }
}

export const getFullUrl = ({
  domain,
  path,
}: {
  domain?: Domain
  path?: string
}) => {
  const pathToUse = !path ? '' : path?.startsWith('/') ? path : `/${path}`
  return `https://${domain?.name}${pathToUse}`
}

export const getSiteToUse = ({
  doc,
  sites,
}: {
  doc?: GetDocQuery['doc']
  sites?: GetSiteQuery['site'][]
}) => {
  const sortedSites = sortBy(sites, 'createdTime') || [] // All sites in this workspace
  const firstSite = sortedSites?.[0] // The first site in this workspace

  const docSiteId = doc?.site?.id // The id of the site already associated with this doc
  const docSite = sortedSites.find((s) => s.id === docSiteId) // The site already associated with this doc

  return docSite || firstSite
}

export const getPathForPathEditor = ({
  site,
  docId,
}: {
  site?: GetSiteQuery['site']
  docId?: string
}) => {
  if (!docId) {
    return undefined
  }
  const siteToUseRoute = site?.routes.find((r) => r.docId === docId)

  // Remove the leading slash from the path because thats what the PathEditor expects
  return (
    siteToUseRoute?.path && removeSlashPrefixForInputField(siteToUseRoute?.path)
  )
}

export const getPathForDocId = ({
  site,
  docId,
}: {
  site?: GetSiteQuery['site']
  docId?: string
}) => {
  if (!docId) {
    return undefined
  }
  const siteToUseRoute = site?.routes.find((r) => r.docId === docId)

  return siteToUseRoute?.path
}

export const getCanonicalHomeRoute = (site?: GetSiteQuery['site']) => {
  if (!site) return
  const home = site?.routes.find((r) => r.path === '/' || r.path === '')
  return home || site?.routes[0]
}

export const getConflictingRoute = ({
  docId,
  path,
  routes,
}: {
  docId?: string
  path?: string
  routes: GetSiteQuery['site']['routes']
}) => {
  return routes.find(
    (r) => r.docId !== docId && (r.path === path || (r.path === '/' && !path))
  )
}

export const forceSaveDoc = async (docId: string) => {
  return fetch(
    `${config.MULTIPLAYER_WS_URL.replace('wss', 'https')}/${docId}/save-doc`,
    {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )
}

/**
 * Ensures that this docId is only associated with zero or one routes (paths), but
 * never more than 1 (even though the API totally supports it, our UX does not, yet).
 * Passing a `null` path to this function is a signal to remove the doc from its route,
 * and passing docId: `null` to the API is a signal to remove that path for the site
 */
export const getUpdatedRoutesForDoc = ({
  routes,
  docId,
  path,
}: {
  routes: Pick<Route, 'docId' | 'path'>[]
  docId: string
  path?: string | null
}) => {
  const updatedRoutes = routes
    .filter((r) => r.docId === docId)
    .map((r) => ({
      path: ensurePathHasForwardSlash(r.path),
      docId: null as unknown as string, // Our generated types dont allow null, but the API does
    }))
  if (path === null) {
    return updatedRoutes
  }
  return updatedRoutes.concat({
    path: ensurePathHasForwardSlash(path) as unknown as string, // Our generated types dont allow null, but the API does
    docId,
  })
}

// If the path is not prefixed with a slash, add one. This is because the API expects it.
export const ensurePathHasForwardSlash = (path?: string) => {
  return path?.startsWith('/') ? path : '/' + path
}

// If the path is prefixed with a slash, remove it. This is because the frontend UI expects it this way
export const removeSlashPrefixForInputField = (path?: string) => {
  return path?.startsWith('/') ? path.slice(1) : path
}

export const getDocIdForHomeRoute = (site: {
  routes: Pick<Route, 'path' | 'docId'>[]
}) => {
  const homeRoute = site.routes.find(
    (r: Pick<Route, 'path'>) => r.path === '/' || r.path === ''
  )?.docId

  return homeRoute || site.routes[0]?.docId
}

export const urlMatchesSitePreviewRoute = (url: string) => {
  const urlPattern = /^\/docs\/[-\w]+\/preview$/
  return urlPattern.test(url)
}

export const getLiveSiteUrl = ({
  site,
  path = '',
}: {
  site?: {
    domains: Array<Pick<Domain, 'name' | 'status' | 'builtin' | 'updatedTime'>>
  }
  path?: string
}) => {
  if (!site) {
    console.error('[getLiveSiteUrl] No site provided')
    return null
  }

  // Sort domains first if they are verified, then non builtins, then by created time descending
  const orderedDomains = orderBy(
    site?.domains,
    [
      (d) => d.status === 'verified',
      (d) => d.builtin === false,
      (d) => new Date(d.updatedTime),
    ],
    ['desc', 'desc', 'desc']
  )
  const domain = orderedDomains[0]

  if (!domain || !site) return null
  return `https://${domain?.name}${path}`
}

export const preparePathForSaving = (path?: string) => {
  return ensurePathHasForwardSlash(path).replace(/-$/, '') // Remove trailing hyphens
}

export const sortDomainsByCreatedDate = (
  domains?: DefaultDomainsFieldsFragment[]
) => {
  return sortBy(domains, 'createdTime').reverse()
}

export const filterVerifiedDomains = (
  domains?: DefaultDomainsFieldsFragment[]
) => {
  return domains?.filter((d) => d.status === 'verified')
}

// Determines if any of the sites have a domain that matches the provided hostname
export const isHostnameInUse = (
  hostname: string,
  sites?: Pick<SiteFragmentFragment, 'domains'>[]
) => {
  return sites?.some((site) =>
    site.domains.some((domain) => domain.name === hostname)
  )
}
