import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'

type MenuPopoverState = {
  cardId: null | string
  isOpen: boolean
}

const initialState: MenuPopoverState = {
  cardId: null,
  isOpen: false,
}

const CardStylingMenuSlice = createSlice({
  name: 'CardStylingMenu',
  initialState,
  reducers: {
    toggle(state, action: PayloadAction<{ cardId: string }>) {
      const { cardId } = action.payload
      state.cardId = !state.isOpen ? cardId : null
      state.isOpen = !state.isOpen
    },
    close(state) {
      state.isOpen = false
      state.cardId = null
    },
  },
})

export const { toggle: toggleCardStylingMenu, close: closeCardStylingMenu } =
  CardStylingMenuSlice.actions

type SliceState = Pick<RootState, 'CardStylingMenu'>

export const selectCardStylingMenuOpen =
  (idToCheck: string) =>
  (state: SliceState): boolean => {
    const { cardId, isOpen } = state.CardStylingMenu
    return isOpen && cardId === idToCheck
  }

export const CardStylingMenuReducer = CardStylingMenuSlice.reducer
