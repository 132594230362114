import memoize from 'fast-memoize'
import UAParserJS from 'ua-parser-js'

// SSR Safe Memoize - See this lodash issue for why: https://github.com/lodash/lodash/issues/5525
// This is a simple memoize function that uses the user agent as the key
const memoizeByUserAgent = (fn: () => boolean) => {
  const cache = new Map<string, boolean>()
  return () => {
    const key = UAParser.getUA()
    const existing = cache.get(key)

    if (existing) {
      return existing
    }

    const result = fn()
    cache.set(key, result)

    return result
  }
}

import { isUAWebview } from './isUAWebview'

const isNil = (value: any) => {
  return value == null
}

declare global {
  interface Navigator {
    brave?: { isBrave: () => Promise<boolean> }
  }
}

export const UAParser = new UAParserJS()

export const isChrome = memoizeByUserAgent(() =>
  Boolean(UAParser.getBrowser().name?.startsWith('Chrome'))
)

const isHeadlessChrome = memoizeByUserAgent(() =>
  Boolean(UAParser.getBrowser().name?.startsWith('Chrome Headless'))
)

export const isSafari = () => UAParser.getEngine().name === 'WebKit'

export const isSafariDesktop = memoizeByUserAgent(
  () => isSafari() && !isMobileDevice()
)

export const isEdgeBrowser = memoizeByUserAgent(() =>
  Boolean(UAParser.getBrowser().name === 'Edge')
)

// Opera mobile may not have a 'mobile' type
const isOperaMobile = () =>
  UAParser.getBrowser().name === 'Opera Mobi' &&
  UAParser.getOS().name === 'Android'

export const isMobileDevice = memoizeByUserAgent(
  () => UAParser.getDevice().type === 'mobile' || isOperaMobile()
)

const isiPad = () => {
  if (typeof navigator === 'undefined') return false

  // iPad on iOS 13 detection
  return (
    !isMobileDevice() &&
    navigator.userAgent.includes('Mac') &&
    'ontouchend' in document
  )
}

// iPad user agents don't display the device as "tablet" --
// when inspecting UA strings, they look identical to desktop devices.
// You need to check some properties on `navigator` to make sure it's really a tablet.
export const isMobileOrTabletDevice = memoizeByUserAgent(
  () =>
    ['mobile', 'tablet'].includes(UAParser.getDevice().type || '') ||
    isOperaMobile() ||
    isiPad()
)

export const isTabletDevice = memoizeByUserAgent(
  () => ['tablet'].includes(UAParser.getDevice().type || '') || isiPad()
)

// forklifted from @tiptap/core since its not exported
// https://github.com/ueberdosis/tiptap/blob/main/packages/core/src/utilities/isiOS.ts
export const isiOS = (): boolean => {
  if (typeof navigator === 'undefined') return false

  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) || isiPad()
  )
}

export const isDesktopDevice = () =>
  isNil(UAParser.getDevice().type) && !isiOS()

export const isWebview = () => {
  if (typeof navigator === 'undefined') return false

  return isUAWebview(navigator.userAgent)
}

// For use in PWAs like Windows App where we don't have tabs
// https://stackoverflow.com/a/57920600
export const isSingleTabBrowser = memoize(() => {
  return ['fullscreen', 'standalone', 'minimal-ui'].some(
    (displayMode) =>
      window.matchMedia('(display-mode: ' + displayMode + ')').matches
  )
})

export const isBrave = () => {
  if (typeof navigator === 'undefined') {
    return undefined
  }
  // https://github.com/brave/brave-browser/issues/10165#issuecomment-644949774
  return typeof navigator.brave?.isBrave === 'function'
}

export const isGammaUserAgent = () => {
  if (typeof navigator === 'undefined') {
    return undefined
  }
  return navigator.userAgent.startsWith('gamma/')
}

// A robot user means a headless chrome runner, which includes but isn't limited to:
// 1. Our own Cypress E2E runner
// 2. Screenshotting service
// 3. PDF service
// This doesn't include web crawlers! To see how we detect those:
// https://github.com/gamma-app/gamma/blob/760799fb176d8b4ce9d8f97bb8aabef94ea01b80/packages/client/src/utils/nextjs.js#L1
export const isRobot = () => isHeadlessChrome() || isGammaUserAgent()

/**
 * Whether it's coming from the file-export service (used for PDFs)
 */
export const isGammaFileExportUserAgent = () => {
  if (typeof navigator === 'undefined') {
    return undefined
  }
  return navigator.userAgent.startsWith('gamma/file-export')
}

export const isWindows = UAParser.getOS().name?.includes('Windows')
