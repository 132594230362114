import { JSONContent } from '@tiptap/core'

export type DocFormat = {
  key: 'deck' | 'webpage' | 'document'
  label: string
  generationLabel: string // The label we pass to AI prompts
  cardLabel: string
  defaultDocContent?: JSONContent[]
  fontSize: number
  contentWidth: number // Characters per line
  primaryAction?: 'present' // Todo: add share, preview/publish, etc
}

export const DocFormats: DocFormat[] = [
  {
    key: 'deck',
    label: 'presentation',
    generationLabel: 'presentation',
    cardLabel: 'slide',
    contentWidth: 95,
    fontSize: 1.25,
    primaryAction: 'present',
  },
  {
    key: 'webpage',
    label: 'page',
    cardLabel: 'section',
    generationLabel: 'webpage',
    contentWidth: 105,
    fontSize: 1.125,
  },
  {
    key: 'document',
    label: 'document',
    cardLabel: 'section',
    generationLabel: 'document',
    contentWidth: 85,
    fontSize: 1,
  },
]
