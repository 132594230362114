import { User } from '@gammatech/schema'
import React, { useContext } from 'react'

import type {
  User as _GraphqlUser,
  UserSettings as GraphqlUserSettings,
  Organization as GraphqlOrganization,
} from 'modules/api/generated/graphql'
import { useGetUser } from 'modules/api/rest'

export type UserSettings = Omit<GraphqlUserSettings, '__typename'>
export type UserSetting = Extract<keyof UserSettings, string>

// Cherry-pick the fields we want from the REST user
// where the optionality of the fields differ
export interface GraphqlUser extends _GraphqlUser {
  email: User['email']
  firstName: User['firstName']
  lastName: User['lastName']
  displayName: User['displayName']
  profileImageUrl: User['profileImageUrl']
  authProvider?: User['authProvider']
}

/**
 * Fallback information to use in the event that we
 * dont know who you are. This is sticky based on a
 * localstorage id set in gamma_user_id
 */
export type AnonymousUser = {
  id: string
  displayName?: string
}

export type UserRefetchFn = ReturnType<typeof useGetUser>['refetch']
export type SetSettingsFn = (settings: {
  set?: Partial<UserSettings>
  remove?: UserSetting[]
}) => Promise<void>

export type UserContextType = {
  user?: GraphqlUser
  currentWorkspace?: GraphqlOrganization
  setCurrentWorkspaceId?: any
  anonymousUser: AnonymousUser
  isUserLoading: boolean
  /**
   * loading - The initial state, we're awaiting the result of the network call
   * loggedIn - We've confirmed the user is logged in (200)
   * loggedOut - We've confirmed the user is logged out (401, 403)
   * error- Something went wrong and we can't say for sure (500, 502, etc...)
   */
  userStatus: 'loading' | 'loggedOut' | 'loggedIn' | 'error'
  isGammaOrgUser: boolean
  color: {
    value: string
    isDark: boolean
  }
  refetch?: UserRefetchFn
  setSettings?: SetSettingsFn
}

export const UserContext = React.createContext<UserContextType>({} as any)

export function useUserContext() {
  const ctx = useContext(UserContext)
  return ctx
}
