import { memo } from 'react'

import { ThemeBase } from '../types'

// Based on https://stackoverflow.com/a/69801798
// and https://codepen.io/mathiesjanssen/pen/QgLzmM
export const Soft3DSmallOuterShadow: ThemeBase['SVGDefs'] = memo(() => {
  const shadowDistance = 0.5
  const shadowBlur = 1
  return (
    <>
      <filter id={`soft3d-small-inner-shadow`}>
        {/* Top left dark inner shadow */}
        <feFlood floodColor="var(--shadow-2-color)" result="flood1" />
        {/* Subtracts the original shape from the shadow color to get a cutout of the original shape */}
        <feComposite
          operator="out"
          in2="SourceGraphic"
          in="flood1"
          result="composite1"
        />
        {/* Stretch the cutout so it overlaps the original shape */}
        <feMorphology
          operator="dilate"
          radius={shadowDistance}
          in="composite1"
          result="morphology1"
        />
        {/* Blur the cutout to get a shadow effect */}
        <feGaussianBlur
          stdDeviation={shadowBlur}
          in="morphology1"
          result="blur1"
        />
        <feOffset
          dx={shadowDistance + shadowBlur}
          dy={shadowDistance + shadowBlur}
          in="blur1"
          result="offset1"
        />
        {/* Repeat the whole operation for the bottom right light inner shadow */}
        <feFlood floodColor="var(--shadow-1-color)" result="flood2" />
        <feComposite
          operator="out"
          in2="SourceGraphic"
          in="flood2"
          result="composite2"
        />
        <feMorphology
          operator="dilate"
          radius={shadowDistance}
          in="composite2"
          result="morphology2"
        />
        <feGaussianBlur
          stdDeviation={shadowBlur}
          in="morphology2"
          result="blur2"
        />
        <feOffset
          dx={-shadowDistance - shadowBlur}
          dy={-shadowDistance - shadowBlur}
          in="blur2"
          result="offset2"
        />
        {/* Merge the two blurry cutouts into one object */}
        <feMerge>
          <feMergeNode in="offset1" />
          <feMergeNode in="offset2" />
        </feMerge>
        {/* Subtract the original shape from the blurry cutouts so we just have the shadows left */}
        <feComposite operator="atop" in2="SourceGraphic" />
      </filter>
    </>
  )
})
Soft3DSmallOuterShadow.displayName = 'Soft3DSmallOuterShadow'
