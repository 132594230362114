import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { getTokenLength } from 'modules/ai/tokens'
import { DocGenerateInput, GenerationType } from 'modules/api'
import { ImportedFile } from 'modules/import/types'
import { RootState } from 'modules/redux'
import { DocFormat } from 'modules/tiptap_editor/extensions/Document/DocumentAttrs/DocFormats'

import {
  DEFAULT_NUM_CARDS,
  GENERATE_INPUT_DEFAULTS,
  TOKEN_PER_CARD_BUFFER,
} from './constants'
import {
  GeneratorInput,
  GeneratorInputSetting,
  PatchGeneratorInput,
} from './types'
import {
  countContentParagraphs,
  countSectionDividers,
  findTextAmount,
} from './utils'

type GeneratorState = {
  currentInput: GeneratorInput
  contentTokenLength: number
  importedFile?: ImportedFile
  // the docGenerateInputs for a given doc generation (history)
  inputs: GeneratorInput[]
  generationType?: GenerationType
  isGeneratingOutline: boolean
  numOutlineBullets: number
}

const initialState: GeneratorState = {
  currentInput: GENERATE_INPUT_DEFAULTS,
  contentTokenLength: 0,
  inputs: [],
  isGeneratingOutline: false,
  numOutlineBullets: DEFAULT_NUM_CARDS,
}

const setGenerateInput = (
  state: GeneratorState,
  currentInput: GeneratorInput
) => {
  state.currentInput = currentInput
  state.contentTokenLength = getTokenLength(currentInput.content)
  // If you reload an existing generation that already has content, default the num bullets to that
  // but don't do it for a new/empty generation
  if (currentInput.content !== '') {
    state.numOutlineBullets = selectCardCount({ AIGenerator: state })
  }
}

const AIGeneratorSlice = createSlice({
  name: 'AIGenerator',
  initialState,
  reducers: {
    reset: () => initialState,
    patchGeneratorInput(
      state: GeneratorState,
      action: PayloadAction<PatchGeneratorInput>
    ) {
      const { settings, ...rest } = action.payload

      const settingsToPatch = settings
        ? {
            ...state.currentInput.settings,
            ...settings,
          }
        : state.currentInput.settings

      state.currentInput = {
        ...state.currentInput,
        ...rest,
        settings: settingsToPatch,
      }
      state.contentTokenLength = getTokenLength(state.currentInput.content)
      // also have to update entry in inputs, to keep them in sync

      const ind = state.inputs.findIndex((a) => a.id === state.currentInput.id)
      if (ind > -1) {
        state.inputs.splice(ind, 1, {
          ...state.currentInput,
        })
      }
    },
    prevVersion(state: GeneratorState) {
      const versions = selectVersionsWithData({ AIGenerator: state })
      const index = versions.findIndex((a) => a.isCurrent)
      if (index === 0) {
        // at edges
        return
      }

      setGenerateInput(state, versions[index - 1].data)
    },
    nextVersion(state: GeneratorState) {
      const versions = selectVersionsWithData({ AIGenerator: state })
      const index = versions.findIndex((a) => a.isCurrent)
      if (index === versions.length - 1) {
        // at edges
        return
      }

      setGenerateInput(state, versions[index + 1].data)
    },
    loadVersionByNum: (
      state: GeneratorState,
      action: PayloadAction<{ num: number }>
    ) => {
      const versions = selectVersionsWithData({ AIGenerator: state })
      const version = versions.find((a) => a.num === action.payload.num)
      if (version) {
        setGenerateInput(state, version.data)
      }
    },
    loadDocGeneration: (
      state: GeneratorState,
      action: PayloadAction<{
        draftInput?: DocGenerateInput
        docGenerateInputs: DocGenerateInput[]
        generationType: GenerationType
      }>
    ) => {
      // if there is a draft input
      // use as the current input
      const { draftInput, docGenerateInputs } = action.payload
      if (draftInput) {
        setGenerateInput(state, draftInput)
        state.inputs = [...docGenerateInputs, draftInput]
      } else {
        // load the last one
        const last = docGenerateInputs[docGenerateInputs.length - 1]
        setGenerateInput(state, last)
        state.inputs = [...docGenerateInputs]
      }
      state.generationType = action.payload.generationType
    },
    handleBackFromGeneratorPage: (state: GeneratorState) => {
      const existingPrompt = state.currentInput.prompt
      state.contentTokenLength = 0
      delete state.importedFile
      state.currentInput = { ...initialState.currentInput }
      state.currentInput.prompt = existingPrompt
      state.inputs = []
    },
    resetPrompt: (state: GeneratorState) => {
      state.currentInput.prompt = ''
    },
    setImportedFile: (
      state: GeneratorState,
      action: PayloadAction<ImportedFile | undefined>
    ) => {
      state.importedFile = action.payload
    },
    setIsGeneratingOutline: (
      state: GeneratorState,
      action: PayloadAction<boolean>
    ) => {
      state.isGeneratingOutline = action.payload
    },
    setNumOutlineBullets: (
      state: GeneratorState,
      action: PayloadAction<number>
    ) => {
      state.numOutlineBullets = action.payload
    },
    incrementNumOutlineBullets: (state: GeneratorState) => {
      state.numOutlineBullets += 1
    },
    decrementNumOutlineBullets: (state: GeneratorState) => {
      state.numOutlineBullets = Math.max(1, state.numOutlineBullets - 1)
    },
  },
})

export const {
  reset,
  setImportedFile,
  resetPrompt,
  loadDocGeneration,
  patchGeneratorInput,
  handleBackFromGeneratorPage,
  nextVersion,
  prevVersion,
  loadVersionByNum,
  setIsGeneratingOutline,
  setNumOutlineBullets,
  incrementNumOutlineBullets,
  decrementNumOutlineBullets,
} = AIGeneratorSlice.actions

type SliceState = Pick<RootState, 'AIGenerator'>

// Selectors
export const selectCurrentInput = (state: SliceState) => {
  const { currentInput } = state.AIGenerator
  if (!currentInput) {
    return currentInput
  }
  return {
    ...currentInput,
    // fix old schema of the AIContentEditor of content that had --- as the first node
    content: currentInput.content.replace(/^---\n/, ''),
  }
}

export const selectGenerationType = (state: SliceState) =>
  state.AIGenerator.generationType

export const selectDocGenerateInputId = (state: SliceState) =>
  state.AIGenerator.currentInput.id

export const selectGenerationId = (state: SliceState) =>
  state.AIGenerator.currentInput.docGenerationId

export const selectInteractionId = (state: SliceState) =>
  state.AIGenerator.currentInput.interactionId

export const selectAllGeneratorSettings = (state: SliceState) =>
  state.AIGenerator.currentInput.settings

export const selectGeneratorSetting =
  <K extends GeneratorInputSetting>(key: K) =>
  (state: SliceState) =>
    state.AIGenerator.currentInput.settings[key]

export const selectPrompt = (state: SliceState) =>
  state.AIGenerator.currentInput.prompt || ''

export const selectFormat = (state: SliceState) =>
  state.AIGenerator.currentInput.format as DocFormat['key']

export const selectContentTokenLength = (state: SliceState) =>
  state.AIGenerator.contentTokenLength

export const selectContent = (state: SliceState) =>
  state.AIGenerator.currentInput.content

export const selectImportedFile = (state: SliceState) =>
  state.AIGenerator.importedFile

export const selectCardCount = (state: SliceState): number => {
  const {
    settings: { editorMode, numCards },
    content,
  } = state.AIGenerator.currentInput
  if (editorMode === 'structured') {
    return countSectionDividers(content)
  } else {
    return numCards ?? DEFAULT_NUM_CARDS
  }
}

export const selectApproxMaxTokens = (state: SliceState): number => {
  const textAmount = findTextAmount(selectGeneratorSetting('textAmount')(state))
  const textMode = selectGeneratorSetting('textMode')(state)
  const numCards = selectCardCount(state)
  if (!numCards) {
    // this should not happen
    return 1500
  }

  if (textMode === 'preserve') {
    const inputLength = state.AIGenerator.contentTokenLength
    return inputLength + TOKEN_PER_CARD_BUFFER * numCards
  }

  // todo: account for language here. some languages are less token efficient
  return textAmount.tokensPerOutputCard * numCards
}

export const selectCanAddSectionBreaks = (state: SliceState) => {
  const mode = selectGeneratorSetting('editorMode')(state)
  if (mode !== 'structured') return false
  const content = selectContent(state)
  const numSections = countSectionDividers(content)
  const numParagraphs = countContentParagraphs(content)
  return numSections <= 2 && numParagraphs >= 6
}

export const selectCanRemoveSectionBreaks = (state: SliceState) => {
  const mode = selectGeneratorSetting('editorMode')(state)
  if (mode !== 'structured') return false
  const content = selectContent(state)
  const numSections = countSectionDividers(content)
  return numSections > 1
}

type Version = {
  id: string
  num: number
  status: GeneratorInput['status']
  isDraft: boolean
  isCurrent: boolean
}

const selectVersionsWithData = (
  state: SliceState
): (Version & { data: GeneratorInput })[] => {
  const { inputs, currentInput } = state.AIGenerator
  const results = inputs.map((v, index) => {
    return {
      id: v.id,
      num: index + 1,
      status: v.status,
      isDraft: v.status === 'draft',
      isCurrent: currentInput.id === v.id,
      data: v,
    }
  })

  return results
}

// this is used in the VersionSwitcher to prevent extraneous renders
export const selectVersions = (state: SliceState): Version[] => {
  return selectVersionsWithData(state).map(({ data: _data, ...rest }) => rest)
}

export const selectHasMultipleVersions = (state: SliceState): boolean => {
  return selectVersions(state).length > 1
}

export const selectCurrentVersion = (state: SliceState): Version => {
  const versions = selectVersions(state)
  return versions.find((a) => a.isCurrent)!
}

export const selectWillOverrideDraft = (state: SliceState): boolean => {
  const { inputs, currentInput } = state.AIGenerator
  const hasDraft = inputs.find((a) => a.status === 'draft')

  return !!hasDraft && currentInput.status !== 'draft'
}

export const selectIsGeneratingOutline = (state: SliceState): boolean =>
  state.AIGenerator.isGeneratingOutline

export const selectNumOutlineBullets = (state: SliceState): number =>
  state.AIGenerator.numOutlineBullets

// Reducer
export const AIGeneratorReducer = AIGeneratorSlice.reducer
